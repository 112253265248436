import { bool } from 'prop-types';
import React, { Fragment } from 'react';

// import ButtonLive from './ButtonLive';
import WeddingInfoBox from './WeddingInfoBox';

import { styWrapper } from './styles';

function WeddingSection({ isInvitation }) {
  const renderGuestInfo = () => {
    return (
      <Fragment>
        <div className="col-md-10 col-md-offset-1">
          <WeddingInfoBox 
            title="Akad Nikah & Resepsi 1"
            time="11.00 WITA - Selesai"
            date="Sabtu, 25 November 2023"
            description="Wonomulyo <br/>Polewali Mandar, Sulawesi Barat" />
          <WeddingInfoBox
            title="Resepsi 2"
            time="10.00 WITA - Selesai"
            date="Ahad, 03 Desember 2023"
            description="BTN Cabalu <br/>Bulukumba, Sulawesi Selatan" />
            {/* <ButtonLive /> */}
        </div>
      </Fragment>
    );
  },
  renderInvitationInfo = () => {
    return (
      <Fragment>
        <div className="col-md-10 col-md-offset-1">
          <WeddingInfoBox 
            title="Akad Nikah & Resepsi 1"
            time="Akan dilaksanakan pagi hari"
            date="Sabtu, 25 November 2023"
            description="Wonomulyo <br/>Polewali Mandar, Sulawesi Barat" />
          <WeddingInfoBox
            title="Resepsi 2"
            time="Akan dilaksanakan pagi hari"
            date="Ahad, 03 Desember 2023"
            description="BTN Cabalu <br/>Bulukumba, Sulawesi Selatan" />
        </div>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <div id="fh5co-event" css={styWrapper}>
        <div className="overlay" />
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
              <span className="bismillah">بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم</span>
              <h2 className="main-font main-font__wedding">Akad Nikah dan Syukuran Pernikahan</h2>
              <span className="sub-title sub-title__wedding">Insya Allah akan diselenggarakan pada:</span>
            </div>
          </div>
          <div className="row">
            {!isInvitation && renderGuestInfo()}
            {isInvitation && renderInvitationInfo()}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

WeddingSection.propTypes = {
  isInvitation: bool.isRequired,
};

export default React.memo(WeddingSection);
