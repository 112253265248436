import { bool } from 'prop-types';
import React, { Fragment } from 'react';
import { styWrapper } from '../HelloSection/styles';

function FooterSection({ isInvitation }) {
  return (
    <Fragment>
      {!isInvitation && (
        <div className="fh5co-section-gray" css={styWrapper}>
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
                <p className="info">
                  Dengan memohon Rahmat dan Ridho Illahi, teriring niat menjalankan Sunnah Rasulullah ﷺ untuk membentuk
                  rumah tangga yang Sakinah, Mawaddah wa Rahmah, kami mohon do'a agar senantiasa diberikan kelancaran dan
                  keberkahan.
                </p>
                <hr className="hr-black"/>
                <p className="info">
                  Atas kekehadiran Bapak/Ibu/Saudara(i),
                  <br /> kami ucapkan terima kasih. <br />
                  Wassalamualaikum warahmatullahi wabarakatuh.
                </p>
                <h2 className="main-font__family">Hormat kami yang mengundang</h2>
                <p className="info"><b>Hamidah Arafah & Ikhwan Alimuddin (Alm)</b></p>
                <p className="info">
                  Kel. Besar Arafah Badening <br/>
                  Kel. Besar H. M. Arafah Bolong <br/>
                  Kel. Besar Alimuddin Tjanda (Alm)
                </p>
                <p className="info">* Kedua Mempelai *</p>
              </div>
            </div>
          </div>
        </div>
      )}
      <footer id="fh5co-footer" role="contentinfo">
        <div className="container">
          <div className="row copyright">
            <div className="col-md-12 text-center">
              <p>
                <small className="block">&copy; 2023 Icar & Lisa Wedding. All Rights Reserved.</small>
                <small className="block">
                  Song by{' '}
                  <a href="https://www.youtube.com/watch?v=fb167KAxvrg" target="_blank" rel="noreferrer">
                    Anandito Anisa - Pernikahan Impian
                  </a>
                </small>
                <small className="block">
                  Original Template from{' '}
                  <a href="http://freehtml5.co/" target="_blank" rel="noreferrer">
                    FREEHTML5.co
                  </a>
                </small>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </Fragment>
  );
}

FooterSection.propTypes = {
  isInvitation: bool.isRequired,
};

export default React.memo(FooterSection);
