/**
 * Link
 */
export const GOOGLE_CALENDAR_LINK = `https://calendar.google.com/calendar/event?action=TEMPLATE&dates=20231125T030000Z%2F20231125T083000Z&text=Icar+%26+Lisa+Wedding&details=Icar+%26+Lisa+Wedding`;
export const GOOGLE_MAPS_LINK = `https://maps.app.goo.gl/d7Ykwemku8xViK2b8`;

/**
 * Wedding time
 */
// Resepsi 1
// export const EPOCH_START_EVENT = 1700881200; // Saturday, November 25, 2023 11:00:00 AM GMT+08:00
// export const EPOCH_END_EVENT = 1700917200; // Saturday, November 25, 2023 9:00:00 PM GMT+08:00
// export const UTC_WEDDING_TIME = '2023-11-25:03:00:00Z'; // Saturday, November 25, 2023 11:00:00 AM GMT+08:00
// Resepsi 2
export const EPOCH_START_EVENT = 1701568800; // Sunday, December 3, 2023 10:00:00 AM GMT+08:00
export const EPOCH_END_EVENT = 1701592200; // Sunday, December 3, 2023 4:30:00 PM GMT+08:00
export const UTC_WEDDING_TIME = '2023-12-03:02:00:00Z'; // Sunday, December 3, 2023 10:00:00 AM GMT+08:00