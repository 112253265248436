import React, { Fragment } from 'react';
import { styWrapper } from './styles';

function LocationSection() {
  return (
    <Fragment>
      <div id="fh5co-couple-story" className="fh5co-section-gray" css={styWrapper}>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2 text-center fh5co-heading animate-box">
              <h2 className="main-font">Lokasi Acara</h2>
              <p className="sub-title">
                <a
                  href="https://maps.app.goo.gl/EZQicJmscA8k9kaSA"
                  title="Click untuk melihat peta di Google Maps"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: '#828282' }}
                >
                  <strong>Akad Nikah & Resepsi 1</strong>
                </a>{' '}
                <br />
                Jl. Anoa, Sidodadi, Kec. Wonomulyo, <br />
                Kabupaten Polewali Mandar, Sulawesi Barat 91352
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 col-md-offset-1">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d7965.574458647375!2d119.20723531219483!3d-3.402015038332142!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zM8KwMjQnMDMuOSJTIDExOcKwMTInMjUuNiJF!5e0!3m2!1sen!2sid!4v1698838303260!5m2!1sen!2sid"
                width="100%"
                height="450"
                frameBorder="0"
                style={{ border: '0' }}
                allowFullScreen
                aria-hidden="false"
                tabIndex="0"
                title="Google Maps - Icar & Lisa Wedding Party"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <div id="fh5co-couple-story" className="fh5co-section-gray" css={styWrapper}>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2 text-center fh5co-heading animate-box">
              <p className="sub-title">
                <a
                  href="https://maps.app.goo.gl/d7Ykwemku8xViK2b8"
                  title="Click untuk melihat peta di Google Maps"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: '#828282' }}
                >
                  <strong>Resepsi 2</strong>
                </a>{' '}
                <br />
                BTN. Cabalu Blok C2/No.4, Desa Paenre Lompoe, Kec. Gantarang, <br />
                Kabupaten Bulukumba, Sulawesi Selatan 92561
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 col-md-offset-1">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15884.330440861202!2d120.1704761!3d-5.5547721!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dbbfff67ed14fe3%3A0xd5fab57023e28ad8!2sPaenre%20Lampoe%2C%20Kec.%20Gantarang%2C%20Kabupaten%20Bulukumba%2C%20Sulawesi%20Selatan%2092561!5e0!3m2!1sid!2sid!4v1698836085368!5m2!1sid!2sid"
                width="100%"
                height="450"
                frameBorder="0"
                style={{ border: '0' }}
                allowFullScreen
                aria-hidden="false"
                tabIndex="0"
                title="Google Maps - Icar & Lisa Wedding Party"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default React.memo(LocationSection);
