import React, { useState } from 'react';

function CashlessSection() {
  const AccountNumber = "1760001341120";
  const LogoUrl = "https://cdn.sibunglon.com/invitation/logo-bank-mandiri.png";
  const [copied, setCopied] = useState(false);

  const handleCopyToClipboard = () => {
    const tempInput = document.createElement('input');
    tempInput.value = AccountNumber;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);

    setCopied(true);

    // Hapus pesan sukses setelah beberapa detik
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  }

  return (
    <div id="fh5co-testimonial">
      <div className="container">
        <div className="row">
          <div className="row">
            <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
              <h2 className="main-font">Gift</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8 col-md-offset-2 text-center">
              <p>Anda dapat memberi ungkapan tanda kasih anda secara cashless dengan mengirim amplop digital secara transfer pada nomor rekening berikut</p>
              <div className="bank-info">
                <img src={LogoUrl} alt="Bank Mandiri" className="logo-bank" loading="lazy" />
                <p><b>{AccountNumber}</b> <br/>a/n <br/>Faizar Septiawan</p>
                <button className="copy-button" onClick={handleCopyToClipboard}>Salin Nomor Rekening</button>
                {copied && <p className="success-message">Nomor rekening telah disalin!</p>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CashlessSection;
